export const APP_URL = process.env.NEXT_PUBLIC_APP_URL?.trim() || '';
export const ZOOM_CLIENT_ID = process.env.NEXT_PUBLIC_ZOOM_CLIENT_ID || '';

export const WS_HOST = process.env.NEXT_PUBLIC_WS_HOST || '';
export const WS_AUTH_ENDPOINT = process.env.NEXT_PUBLIC_WS_AUTH_ENDPOINT || '';
export const WS_PORT = process.env.NEXT_PUBLIC_WS_PORT || '';
export const WS_KEY = process.env.NEXT_PUBLIC_WS_KEY || '';

export const CMS_URL = process.env.NEXT_PUBLIC_CMS_URL || '';
export const CMS_APIKEY = process.env.NEXT_PUBLIC_CMS_APIKEY || '';
